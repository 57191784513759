import {mapActions, mapGetters, mapMutations} from "vuex";

import {dynamicHead} from '@/mixins/dynamic-head.js'

export default {
  name: "success",
  mixins: [dynamicHead],
  components:{
  },
  data(){
    return{
      params: {
        title: '',
        description: '',
        keywords: '',
        image: '',
      },
    }
  },
  created() {
    this.fetchMeta('home').then(()=>{
      //this.setParams()
      //this.setMeta()
    }).catch(error=>{
      console.log(error)
    })
    localStorage.removeItem('cart_id')
    if(localStorage.getItem('purchaseData')){
      this.pushEvent(JSON.parse(localStorage.getItem('purchaseData')))
    }
  },
  computed:{
    ...mapGetters({
      meta: 'meta/meta',
    })
  },

  methods:{
    ...mapActions({
      fetchMeta: 'meta/GET_META',
    }),
    ...mapMutations({
    }),
    pushEvent(obj){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(obj);
      localStorage.removeItem('purchaseData')
    },
    setParams(){
      this.params.title = this.meta.metaTitle;
      this.params.description = this.meta.metaDescription;
      this.params.keywords = this.meta.metaKeywords;
      this.params.image = this.meta.metaImage;
    }
  }
}